import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { ArrowRight, Calendar, User } from 'lucide-react';

const PackingGuide = () => {
  const location = useLocation();
  
  // Check if user prefers reduced motion
  const prefersReducedMotion = 
    typeof window !== 'undefined' 
      ? window.matchMedia('(prefers-reduced-motion: reduce)').matches 
      : false;
  
  useEffect(() => {
    // Scroll to top with animation when the blog page loads
    const scrollToTop = () => {
      const scrollOptions: ScrollToOptions = {
        top: 0,
        behavior: prefersReducedMotion ? 'auto' : 'smooth'
      };
      
      window.scrollTo(scrollOptions);
    };
    
    // Execute scroll on initial load and when hash changes
    if (location.hash) {
      // If there's a hash, wait a bit to ensure the DOM is ready
      setTimeout(() => {
        const element = document.getElementById(location.hash.slice(1));
        if (element) {
          element.scrollIntoView({
            behavior: prefersReducedMotion ? 'auto' : 'smooth'
          });
        }
      }, 100);
    } else {
      scrollToTop();
    }
  }, [location, prefersReducedMotion]);

  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-5xl font-bold mb-6">Professional Packing Guide: Protect Your Valuables</h1>
            <div className="flex items-center justify-center space-x-4 text-gray-300">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2" />
                <span>March 10, 2025</span>
              </div>
              <div className="flex items-center">
                <User className="h-4 w-4 mr-2" />
                <span>By Omex Team</span>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Blog Content */}
      <section className="py-20">
        <div className="max-w-4xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="prose prose-lg max-w-none"
          >
            <h2 id="art-of-packing">The Art of Professional Packing</h2>
            
            <p>Proper packing is the foundation of a successful move. At Omex Packers and Movers, we've perfected the art of packing through years of experience and continuous improvement of our techniques. In this comprehensive guide, we'll share our professional packing methods to help you protect your valuables during relocation.</p>
            
            <p>Whether you're moving across the street or across the country, these expert packing tips will ensure your belongings arrive at your new home in perfect condition.</p>

            <h3 id="supplies">Essential Packing Supplies</h3>
            
            <p>Before you begin packing, gather all the necessary supplies. Using the right materials is crucial for protecting your items during transit:</p>
            
            <ul>
              <li><strong>Moving Boxes:</strong> Various sizes for different items (small for heavy items, large for lightweight items)</li>
              <li><strong>Packing Paper:</strong> Unprinted newsprint paper for wrapping items</li>
              <li><strong>Bubble Wrap:</strong> For fragile items that need extra protection</li>
              <li><strong>Packing Tape:</strong> Heavy-duty tape for securing boxes</li>
              <li><strong>Markers:</strong> For labeling boxes with contents and destination rooms</li>
              <li><strong>Furniture Blankets:</strong> To protect furniture surfaces</li>
              <li><strong>Plastic Wrap:</strong> For securing drawers and protecting upholstery</li>
              <li><strong>Specialty Containers:</strong> For artwork, electronics, and other valuable items</li>
            </ul>
            
            <p>Investing in quality packing materials may seem expensive initially, but it's far less costly than replacing damaged items. Professional-grade materials provide superior protection compared to makeshift alternatives.</p>

            <h3 id="room-by-room">Room-by-Room Packing Techniques</h3>
            
            <h4 id="kitchen">Kitchen</h4>
            <p>The kitchen often contains the most fragile items in your home:</p>
            
            <ul>
              <li><strong>Dishes:</strong> Wrap each plate individually and stack them vertically (not flat) in boxes</li>
              <li><strong>Glasses:</strong> Stuff cups and glasses with packing paper and wrap individually</li>
              <li><strong>Appliances:</strong> Remove removable parts, secure cords, and pack in original boxes if available</li>
              <li><strong>Pots and Pans:</strong> Nest smaller ones inside larger ones with paper between them</li>
              <li><strong>Utensils:</strong> Bundle similar items together and wrap with rubber bands</li>
            </ul>
            
            <h4 id="living-room">Living Room</h4>
            <p>Living rooms often contain valuable electronics and decorative items:</p>
            
            <ul>
              <li><strong>Electronics:</strong> Use original boxes when possible; otherwise, wrap in bubble wrap and pack in sturdy boxes</li>
              <li><strong>Lamps:</strong> Remove bulbs and lampshades and pack separately</li>
              <li><strong>Artwork:</strong> Use specialty art boxes or create corner protectors with cardboard</li>
              <li><strong>Books:</strong> Pack in small boxes (books get heavy quickly)</li>
            </ul>
            
            <h4 id="bedroom">Bedroom</h4>
            <p>Bedrooms contain a mix of clothing and personal items:</p>
            
            <ul>
              <li><strong>Clothing:</strong> Use wardrobe boxes for hanging clothes; fold others neatly in suitcases or boxes</li>
              <li><strong>Jewelry:</strong> Keep valuable jewelry with you during the move</li>
              <li><strong>Bedding:</strong> Pack in clean plastic bags or boxes labeled for immediate use</li>
            </ul>

            <h3 id="fragile-items">Special Packing Techniques for Fragile Items</h3>
            
            <p>Some items require extra attention and specialized packing techniques:</p>
            
            <h4 id="china">Fine China and Crystal</h4>
            <p>Double-wrap each piece in packing paper, then bubble wrap. Use cell kits (dividers) in boxes for additional protection. Mark these boxes as "FRAGILE" and "THIS SIDE UP."</p>
            
            <h4 id="artwork">Artwork and Mirrors</h4>
            <p>Use specialty art boxes or create an X with tape across glass surfaces before wrapping. Sandwich between pieces of cardboard and wrap the entire package in bubble wrap.</p>
            
            <h4 id="electronics">Electronics</h4>
            <p>Take photos of wire connections before disconnecting. Place screws and small parts in labeled bags. Wrap items in anti-static bubble wrap and pack in boxes with plenty of cushioning.</p>
            
            <h4 id="antiques">Antiques</h4>
            <p>Consult with a professional for valuable antiques. Use acid-free packing materials and custom crates for extremely valuable pieces.</p>

            <h3 id="tips">Professional Packing Tips</h3>
            
            <p>These expert tips will help you pack like a professional:</p>
            
            <ol>
              <li><strong>Start early:</strong> Begin packing non-essential items weeks before your move</li>
              <li><strong>Pack room by room:</strong> This keeps items organized and makes unpacking easier</li>
              <li><strong>Use the right box size:</strong> Heavy items in small boxes, light items in large boxes</li>
              <li><strong>Fill empty spaces:</strong> Use packing paper or bubble wrap to fill gaps in boxes</li>
              <li><strong>Double-tape box bottoms:</strong> Reinforce the bottom of boxes with an H-pattern of tape</li>
              <li><strong>Label thoroughly:</strong> Mark contents, room destination, and special handling instructions</li>
              <li><strong>Create an inventory:</strong> Keep a list of what's in each numbered box</li>
              <li><strong>Pack an essentials box:</strong> Include items you'll need immediately upon arrival</li>
            </ol>

            <h3 id="how-we-help">How Omex Packers and Movers Can Help</h3>
            
            <p>While these tips will help you pack effectively, professional packing services offer several advantages:</p>
            
            <ul>
              <li><strong>Efficiency:</strong> Our teams can pack an entire home in a fraction of the time it would take most homeowners</li>
              <li><strong>Expertise:</strong> We have specialized techniques for even the most challenging items</li>
              <li><strong>Materials:</strong> We use professional-grade packing supplies that provide superior protection</li>
              <li><strong>Insurance:</strong> Professional packing often provides better coverage under moving insurance</li>
              <li><strong>Peace of Mind:</strong> Know that your valuables are in expert hands</li>
            </ul>
            
            <p>Omex Packers and Movers offers full-service packing, partial packing, or just packing for specialty items. Our flexible options allow you to choose the level of service that fits your needs and budget.</p>
            
            <div className="mt-8 flex justify-center">
              <Link to="/contact" className="btn-primary">
                Get a Free Packing Quote <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Related Posts */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="section-title">Related Articles</h2>
            <p className="section-subtitle">
              Explore more moving tips and guides
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Essential Moving Tips for a Stress-Free Relocation",
                excerpt: "Expert advice on planning, packing, and executing your move efficiently.",
                image: "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80",
                date: "March 15, 2025",
                link: "/blog/moving-tips"
              },
              {
                title: "Ultimate Moving Checklist",
                excerpt: "A comprehensive timeline to ensure nothing is overlooked during your move.",
                image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80",
                date: "March 5, 2025",
                link: "/blog/moving-checklist"
              },
              {
                title: "How to Choose the Right Moving Insurance",
                excerpt: "Understanding different insurance options to protect your belongings during transit.",
                image: "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80",
                date: "February 28, 2025",
                link: "/blog/moving-insurance"
              }
            ].map((post, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-lg overflow-hidden shadow-lg"
              >
                <div className="h-48 overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                  />
                </div>
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-500 mb-2">
                    <Calendar className="h-4 w-4 mr-2" />
                    <span>{post.date}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-2">{post.title}</h3>
                  <p className="text-gray-600 mb-4">{post.excerpt}</p>
                  <Link to={post.link} className="text-blue-600 hover:text-blue-800 transition-colors inline-flex items-center">
                    Read More <ArrowRight className="ml-1 h-4 w-4" />
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PackingGuide;