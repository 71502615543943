import React from 'react';
import { Phone, Mail, MapPin, Truck } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  // Function to handle smooth scroll to top when clicking blog links
  const handleBlogLinkClick = () => {
    // The actual scrolling is handled by the ScrollToTop component
    // This is just to ensure we have a consistent behavior
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4 transition-all duration-300 hover:scale-105">
              <Truck className="h-8 w-8 text-blue-400" />
              <span className="text-2xl font-bold">Omex Packers</span>
            </div>
            <p className="text-gray-400">
              Your trusted partner in safe and reliable relocation services across India.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Latest Blog Posts</h3>
            <ul className="space-y-4">
              <li>
                <Link to="/blog/moving-tips" className="group" onClick={handleBlogLinkClick}>
                  <h4 className="text-gray-400 font-medium hover:text-white transition-colors">Essential Moving Tips for a Stress-Free Relocation</h4>
                  <p className="text-sm text-gray-500 group-hover:text-gray-400 transition-colors">A Word of Caution: When choosing moving services, research is key. Learn how to identify quality movers and avoid common pitfalls.</p>
                </Link>
              </li>
              <li>
                <Link to="/blog/packing-guide" className="group" onClick={handleBlogLinkClick}>
                  <h4 className="text-gray-400 font-medium hover:text-white transition-colors">Professional Packing Guide: Protect Your Valuables</h4>
                  <p className="text-sm text-gray-500 group-hover:text-gray-400 transition-colors">Learn industry-best practices for securing your precious items during relocation.</p>
                </Link>
              </li>
              <li>
                <Link to="/blog/moving-checklist" className="group" onClick={handleBlogLinkClick}>
                  <h4 className="text-gray-400 font-medium hover:text-white transition-colors">Ultimate Moving Checklist</h4>
                  <p className="text-sm text-gray-500 group-hover:text-gray-400 transition-colors">A comprehensive timeline to ensure nothing is overlooked during your move.</p>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Info</h3>
            <ul className="space-y-2">
              <li className="contact-info">
                <Phone className="h-5 w-5 text-blue-400" />
                <a href="tel:+919999011150" className="text-gray-400 hover:text-white transition-colors">+91 99990 11150</a>
              </li>
              <li className="contact-info">
                <Mail className="h-5 w-5 text-blue-400" />
                <a href="mailto:omexpackers@gmail.com" className="text-gray-400 hover:text-white transition-colors">omexpackers@gmail.com</a>
              </li>
              <li className="contact-info">
                <MapPin className="h-5 w-5 text-blue-400" />
                <span className="text-gray-400">Admin Office - 118 Apsara Cinema Complex, Shahdara UP Border, Ghaziabad</span>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Sitemap</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-gray-400 hover:text-white transition-colors">Home</Link></li>
              <li><Link to="/services" className="text-gray-400 hover:text-white transition-colors">Services</Link></li>
              <li><Link to="/about" className="text-gray-400 hover:text-white transition-colors">About Us</Link></li>
              <li><Link to="/portfolio" className="text-gray-400 hover:text-white transition-colors">Portfolio</Link></li>
              <li><Link to="/testimonials" className="text-gray-400 hover:text-white transition-colors">Testimonials</Link></li>
              <li><Link to="/contact" className="text-gray-400 hover:text-white transition-colors">Contact Us</Link></li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Omex Packers & Movers. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;