import React from 'react';
import { motion } from 'framer-motion';
import { Award, Users, Clock, Shield } from 'lucide-react';

const About = () => {
  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="section-title">About Us</h2>
          <p className="section-subtitle">
            Your trusted partner in relocation services since 2005
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-20">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <img
              src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              alt="Our Story"
              className="rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <h3 className="text-3xl font-bold mb-6">Our Story</h3>
            <p className="text-gray-600 mb-6">
              Founded in 2005, Omex Packers & Movers has grown from a small local moving company to one of India's most trusted relocation service providers. Our journey has been built on the foundation of trust, reliability, and customer satisfaction.
            </p>
            <p className="text-gray-600">
              With over a Decade of experience in the industry, we have successfully completed more than 10,000 moves, serving both residential and commercial clients across India.
            </p>
          </motion.div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {[
            {
              icon: <Award className="h-12 w-12 text-blue-600" />,
              title: "Excellence",
              description: "We strive for excellence in every move"
            },
            {
              icon: <Users className="h-12 w-12 text-blue-600" />,
              title: "Customer First",
              description: "Your satisfaction is our top priority"
            },
            {
              icon: <Clock className="h-12 w-12 text-blue-600" />,
              title: "Punctuality",
              description: "We value your time and stick to schedules"
            },
            {
              icon: <Shield className="h-12 w-12 text-blue-600" />,
              title: "Reliability",
              description: "Trust us with your valuable possessions"
            }
          ].map((value, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <div className="flex justify-center mb-4">{value.icon}</div>
              <h3 className="text-xl font-bold mb-2">{value.title}</h3>
              <p className="text-gray-600">{value.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;