import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Phone, Mail, MapPin, Send, User, MessageSquare, Clock } from 'lucide-react';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    service: 'residential'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFocus = (fieldName: string) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      // Using the working form submission code from the second implementation
      const formUrl = 
        "https://docs.google.com/forms/d/e/1FAIpQLSdaDFUL0IfAAZwOPolykmEBrmDIQzCdkCjOs76KSqRohSaxIA/formResponse";

      // Create form data to submit - using the field mappings from the second code
      const formSubmitData = new FormData();
      formSubmitData.append('entry.2005620554', `${formData.firstName} ${formData.lastName}`); // Name field
      formSubmitData.append('entry.1045781291', formData.email); // Email field
      formSubmitData.append('entry.1166974658', formData.phone); // Phone field
      formSubmitData.append('entry.839337160', formData.message); // Message field

      // Use fetch with no-cors mode to submit the form
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formSubmitData
      });

      // Since we can't actually check the response with no-cors, we'll assume success
      setIsSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        service: 'residential'
      });
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('There was an error submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  const pulseAnimation = {
    scale: [1, 1.05, 1],
    transition: { duration: 1.5, repeat: Infinity }
  };

  // Common input wrapper styles
  const inputWrapperStyles = "relative cursor-pointer";

  // Common label styles
  const getLabelStyles = (fieldName: string) => `
    absolute left-3 top-3
    transition-all duration-300
    ${focusedField === fieldName || formData[fieldName as keyof typeof formData]
      ? 'text-blue-600 -translate-y-7 text-xs'
      : 'text-gray-500'
    }
  `;

  // Common input styles
  const getInputStyles = (fieldName: string) => `
    w-full px-4 py-3 border-2 rounded-lg
    focus:outline-none transition-all duration-300 pt-4
    ${focusedField === fieldName ? 'border-blue-600 bg-blue-50' : 'border-gray-300'}
  `;

  const handleLabelClick = (fieldName: string) => {
    setFocusedField(fieldName);
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-gradient-to-r from-blue-700 to-blue-900">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-blue-900 opacity-30"></div>
          <div className="absolute -bottom-32 -left-32 w-96 h-96 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
          <div className="absolute -bottom-32 -right-32 w-96 h-96 bg-blue-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
          <div className="absolute top-32 left-1/2 w-96 h-96 bg-blue-400 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-4000"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-5xl font-bold mb-6">Contact Us</h1>
            <p className="text-xl text-blue-100 max-w-2xl mx-auto">
              Get in touch with us for all your relocation needs
            </p>
          </motion.div>
        </div>
      </section>

      {/* Contact Information */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16"
          >
            {[
              {
                icon: <Phone className="h-10 w-10 text-white" />,
                title: "Phone",
                info: "+91 99990 11150",
                link: "tel:+919999011150",
                color: "bg-gradient-to-br from-blue-500 to-blue-700"
              },
              {
                icon: <Mail className="h-10 w-10 text-white" />,
                title: "Email",
                info: "omexpackers@gmail.com",
                link: "mailto:omexpackers@gmail.com",
                color: "bg-gradient-to-br from-blue-600 to-blue-800"
              },
              {
                icon: <MapPin className="h-10 w-10 text-white" />,
                title: "Address",
                info: "Admin Office - 118 Apsara Cinema Complex, Shahdara UP Border, Ghaziabad",
                link: "#",
                color: "bg-gradient-to-br from-blue-700 to-blue-900"
              }
            ].map((contact, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="transform transition-all duration-300 hover:scale-105"
              >
                <div className="bg-white rounded-lg shadow-xl overflow-hidden h-full">
                  <div className={`${contact.color} p-6 flex justify-center`}>
                    <motion.div
                      animate={pulseAnimation}
                      className="w-20 h-20 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center"
                    >
                      {contact.icon}
                    </motion.div>
                  </div>
                  <div className="p-6 text-center">
                    <h3 className="text-xl font-bold mb-2">{contact.title}</h3>
                    <a
                      href={contact.link}
                      className="text-gray-600 hover:text-blue-600 transition-colors"
                    >
                      {contact.info}
                    </a>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Contact Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto"
          >
            <div className="bg-white rounded-xl shadow-2xl overflow-hidden">
              {isSubmitted ? (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  className="p-12 text-center"
                >
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1, rotate: [0, 10, -10, 0] }}
                    transition={{ delay: 0.2, duration: 0.8, type: "spring" }}
                    className="w-24 h-24 bg-gradient-to-br from-green-400 to-green-600 rounded-full flex items-center justify-center mx-auto mb-6"
                  >
                    <svg className="h-12 w-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </motion.div>
                  <motion.h3
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className="text-3xl font-bold text-gray-800 mb-4"
                  >
                    Thank You!
                  </motion.h3>
                  <motion.p
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.6 }}
                    className="text-gray-600 text-lg mb-8"
                  >
                    Your message has been sent successfully. We'll get back to you soon.
                  </motion.p>
                  <motion.button
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsSubmitted(false)}
                    className="px-8 py-3 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-lg hover:shadow-lg transition-all duration-300"
                  >
                    Send Another Message
                  </motion.button>
                </motion.div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-5">
                  <div className="md:col-span-2 bg-gradient-to-br from-blue-600 to-blue-900 p-8 text-white flex flex-col justify-center">
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: true }}
                    >
                      <h3 className="text-2xl font-bold mb-6">Let's Get In Touch</h3>
                      <p className="mb-8 text-blue-100">
                        Fill out the form and our team will get back to you within 24 hours.
                      </p>

                      <div className="space-y-6">
                        <div className="flex items-center space-x-4">
                          <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                            <Phone className="h-5 w-5" />
                          </div>
                          <div>
                            <p className="text-sm text-blue-100">Phone</p>
                            <p>+91 99990 11150</p>
                          </div>
                        </div>

                        <div className="flex items-center space-x-4">
                          <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                            <Mail className="h-5 w-5" />
                          </div>
                          <div>
                            <p className="text-sm text-blue-100">Email</p>
                            <p>omexpackers@gmail.com</p>
                          </div>
                        </div>

                        <div className="flex items-center space-x-4">
                          <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                            <Clock className="h-5 w-5" />
                          </div>
                          <div>
                            <p className="text-sm text-blue-100">Working Hours</p>
                            <p>24/7</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>

                  <div className="md:col-span-3 p-8">
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-6">
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                          viewport={{ once: true }}
                        >
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className={inputWrapperStyles} onClick={() => handleLabelClick('firstName')}>
                              <div className={getLabelStyles('firstName')}>
                                <User className="h-5 w-5 inline mr-2" />
                                <span>First Name</span>
                              </div>
                              <input
                                id="firstName"
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                onFocus={() => handleFocus('firstName')}
                                onBlur={handleBlur}
                                required
                                className={getInputStyles('firstName')}
                              />
                            </div>
                            <div className={inputWrapperStyles} onClick={() => handleLabelClick('lastName')}>
                              <div className={getLabelStyles('lastName')}>
                                <User className="h-5 w-5 inline mr-2" />
                                <span>Last Name</span>
                              </div>
                              <input
                                id="lastName"
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lastName')}
                                onBlur={handleBlur}
                                required
                                className={getInputStyles('lastName')}
                              />
                            </div>
                          </div>
                        </motion.div>

                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3, delay: 0.1 }}
                          viewport={{ once: true }}
                        >
                          <div className={inputWrapperStyles} onClick={() => handleLabelClick('phone')}>
                            <div className={getLabelStyles('phone')}>
                              <Phone className="h-5 w-5 inline mr-2" />
                              <span>Phone Number</span>
                            </div>
                            <input
                              id="phone"
                              type="tel"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              onFocus={() => handleFocus('phone')}
                              onBlur={handleBlur}
                              required
                              className={getInputStyles('phone')}
                            />
                          </div>
                        </motion.div>

                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3, delay: 0.2 }}
                          viewport={{ once: true }}
                        >
                          <div className={inputWrapperStyles} onClick={() => handleLabelClick('email')}>
                            <div className={getLabelStyles('email')}>
                              <Mail className="h-5 w-5 inline mr-2" />
                              <span>Email Address</span>
                            </div>
                            <input
                              id="email"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              onFocus={() => handleFocus('email')}
                              onBlur={handleBlur}
                              required
                              className={getInputStyles('email')}
                            />
                          </div>
                        </motion.div>

                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3, delay: 0.3 }}
                          viewport={{ once: true }}
                        >
                          <div className={inputWrapperStyles} onClick={() => handleLabelClick('message')}>
                            <div className={getLabelStyles('message')}>
                              <MessageSquare className="h-5 w-5 inline mr-2" />
                              <span>Your Message</span>
                            </div>
                            <textarea
                              id="message"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              onFocus={() => handleFocus('message')}
                              onBlur={handleBlur}
                              required
                              rows={5}
                              className={getInputStyles('message')}
                            ></textarea>
                          </div>
                        </motion.div>

                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3, delay: 0.4 }}
                          viewport={{ once: true }}
                        >
                          <div className="flex justify-end">
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              type="submit"
                              disabled={isSubmitting}
                              className="px-8 py-3 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-lg flex items-center space-x-2 hover:shadow-lg transition-all duration-300"
                            >
                              {isSubmitting ? (
                                <>
                                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  <span>Sending...</span>
                                </>
                              ) : (
                                <>
                                  <Send className="h-5 w-5" />
                                  <span>Send Message</span>
                                </>
                              )}
                            </motion.button>
                          </div>
                        </motion.div>

                        {error && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm"
                          >
                            {error}
                          </motion.div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Contact;