import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "What areas do you service?",
    answer: "We provide comprehensive moving services across all major cities in India, with specialized services for both domestic and international relocations."
  },
  {
    question: "How do you ensure the safety of items during moving?",
    answer: "We use high-quality packing materials and follow industry-best practices for securing items. Additionally, all shipments are insured for added protection."
  },
  {
    question: "What is included in your basic moving service?",
    answer: "Our basic service includes professional packing, loading, transportation, unloading, and basic unpacking. We also offer additional services like storage and specialty item handling."
  },
  {
    question: "How far in advance should I book your services?",
    answer: "We recommend booking at least 2-3 weeks in advance for local moves and 4-6 weeks for long-distance or international moves to ensure availability."
  },
  {
    question: "Do you provide storage solutions?",
    answer: "Yes, we offer both short-term and long-term storage solutions in our secure, climate-controlled facilities for all your storage needs."
  },
  {
    question: "What is your pricing structure?",
    answer: "Our pricing is transparent and based on factors like distance, volume of items, and specific services required. We provide detailed quotes after a thorough assessment."
  }
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="section-title">Frequently Asked Questions</h2>
          <p className="section-subtitle">
            Find answers to common questions about our services
          </p>
        </motion.div>

        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="faq-item"
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="faq-question w-full"
              >
                <span>{faq.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="h-5 w-5" />
                ) : (
                  <ChevronDown className="h-5 w-5" />
                )}
              </button>
              {openIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="faq-answer"
                >
                  {faq.answer}
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;