import React from 'react';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';

const testimonials = [
  {
    name: "Sahil Kumar",
    role: "Homeowner",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    text: "Excellent service! The team was professional and handled my belongings with utmost care. The entire process was smooth and stress-free.",
    rating: 5,
    date: "March 2024"
  },
  {
    name: "Ramesh Singh",
    role: "Business Owner",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    text: "Very satisfied with the moving service. Everything arrived safely and on time. Their commercial moving expertise is outstanding.",
    rating: 5,
    date: "February 2024"
  },
  {
    name: "Rajkumar Agarwal",
    role: "Apartment Resident",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    text: "Great experience! The staff was friendly and the pricing was transparent. Would definitely recommend their services.",
    rating: 5,
    date: "January 2024"
  },
  {
    name: "Emily Brown",
    role: "Restaurant Owner",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    text: "They handled our restaurant equipment with exceptional care. The team was efficient and professional throughout the entire move.",
    rating: 5,
    date: "March 2024"
  },
  {
    name: "David Chen",
    role: "IT Professional",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    text: "Relocated my home office setup without any issues. All my equipment arrived safely. Very professional service!",
    rating: 5,
    date: "February 2024"
  },
  {
    name: "Lisa Anderson",
    role: "Interior Designer",
    image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    text: "Their attention to detail is impressive. They took extra care with my valuable furniture and art pieces.",
    rating: 5,
    date: "January 2024"
  }
];

const Testimonials = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-5xl font-bold mb-6">Client Testimonials</h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Read what our satisfied customers have to say about their experience with us
            </p>
          </motion.div>
        </div>
      </section>

      {/* Testimonials Grid */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-lg shadow-lg p-8"
              >
                <div className="flex items-center mb-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-16 h-16 rounded-full object-cover mr-4"
                  />
                  <div>
                    <h3 className="font-bold text-lg">{testimonial.name}</h3>
                    <p className="text-gray-600 text-sm">{testimonial.role}</p>
                  </div>
                </div>
                <div className="flex mb-4">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.text}</p>
                <p className="text-sm text-gray-500">{testimonial.date}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-4">Ready to Experience Our Service?</h2>
            <p className="text-xl mb-8">Join our growing list of satisfied customers</p>
            <a
              href="/contact"
              className="bg-white text-blue-600 px-8 py-3 rounded-md hover:bg-gray-100 transition-colors inline-block"
            >
              Get a Free Quote
            </a>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;