import React from 'react';
import { motion } from 'framer-motion';

const clients = [
  {
    name: 'LG Electronics',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/LG_logo_%282014%29.svg/800px-LG_logo_%282014%29.svg.png',
    url: 'https://www.lg.com'
  },
  {
    name: 'Panasonic',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Panasonic_logo_%28Blue%29.svg/2560px-Panasonic_logo_%28Blue%29.svg.png',
    url: 'https://www.panasonic.com'
  },
  {
    name: 'Zydus Wellness',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Zydus_Logo.jpg',
    url: 'https://www.zyduswellness.com'
  },
  {
    name: 'Cargil India',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/CargillLogo.svg/1200px-CargillLogo.svg.png',
    url: 'https://www.cargill.com'
  },
  {
    name: 'Jagjit Industries',
    logo: 'https://www.equitybulls.com/equitybullsadmin/uploads/Jagatjit%20Industries%20Limited%20Logo.jpg',
    url: 'https://www.jagjitindustries.com'
  },
  {
    name: 'CocaCola',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png',
    url: 'https://www.coca-cola.com'
  },
  {
    name: 'ION Trading',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/4/45/ION_Group.svg',
    url: 'https://www.iongroup.com'
  },
  {
    name: 'HCL Comnet',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/HCL_Technologies_logo.svg/1233px-HCL_Technologies_logo.svg.png',
    url: 'https://www.hcltech.com'
  },
  {
    name: 'Brickred',
    logo: 'https://www.brickredsys.com/wp-content/uploads/2023/12/Group-342.png',
    url: 'https://www.brickred.com'
  },
  {
    name: 'Pantos',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/LX_logo.svg/1200px-LX_logo.svg.png',
    url: 'https://www.lg.com'
  },
  {
    name: 'PVR Cinemas',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/d/dc/Pvrcinemas_logo.jpg',
    url: 'https://www.lg.com'
  },
  
];

const ClientShowcase = () => {
  return (
    <section className="py-20 bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="section-title">Trusted by Industry Leaders</h2>
          <p className="section-subtitle">
            Providing premium relocation services to prestigious organizations
          </p>
        </motion.div>

        <div className="relative w-full">
          {/* First row of logos */}
          <div className="flex animate-scroll whitespace-nowrap">
            {[...clients, ...clients].map((client, index) => (
              <div
                key={`${client.name}-${index}`}
                className="flex-none mx-8 w-[200px]"
              >
                <a
                  href={client.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group block"
                >
                  <div className="h-[100px] bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 p-4 flex items-center justify-center">
                    <img
                      src={client.logo}
                      alt={client.name}
                      className="max-w-full max-h-full object-contain transition-transform duration-300 group-hover:scale-110"
                    />
                  </div>
                  <p className="text-sm text-center mt-2 text-gray-600 group-hover:text-blue-600 transition-colors duration-300">
                    {client.name}
                  </p>
                </a>
              </div>
            ))}
          </div>

          {/* Second row of logos (scrolling in opposite direction) */}
          <div className="flex animate-scroll whitespace-nowrap mt-8" style={{ animationDirection: 'reverse' }}>
            {[...clients, ...clients].map((client, index) => (
              <div
                key={`${client.name}-reverse-${index}`}
                className="flex-none mx-8 w-[200px]"
              >
                <a
                  href={client.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group block"
                >
                  <div className="h-[100px] bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 p-4 flex items-center justify-center">
                    <img
                      src={client.logo}
                      alt={client.name}
                      className="max-w-full max-h-full object-contain transition-transform duration-300 group-hover:scale-110"
                    />
                  </div>
                  <p className="text-sm text-center mt-2 text-gray-600 group-hover:text-blue-600 transition-colors duration-300">
                    {client.name}
                  </p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientShowcase;