import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { ArrowRight, Calendar, User } from 'lucide-react';

const Blog = () => {
  const location = useLocation();
  
  // Check if user prefers reduced motion
  const prefersReducedMotion = 
    typeof window !== 'undefined' 
      ? window.matchMedia('(prefers-reduced-motion: reduce)').matches 
      : false;
  
  useEffect(() => {
    // Scroll to top with animation when the blog page loads
    const scrollToTop = () => {
      const scrollOptions: ScrollToOptions = {
        top: 0,
        behavior: prefersReducedMotion ? 'auto' : 'smooth'
      };
      
      window.scrollTo(scrollOptions);
    };
    
    // Execute scroll on initial load and when hash changes
    if (location.hash) {
      // If there's a hash, wait a bit to ensure the DOM is ready
      setTimeout(() => {
        const element = document.getElementById(location.hash.slice(1));
        if (element) {
          element.scrollIntoView({
            behavior: prefersReducedMotion ? 'auto' : 'smooth'
          });
        }
      }, 100);
    } else {
      scrollToTop();
    }
  }, [location, prefersReducedMotion]);

  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-5xl font-bold mb-6">The Truth About Moving Companies: A Cautionary Tale</h1>
            <div className="flex items-center justify-center space-x-4 text-gray-300">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2" />
                <span>March 15, 2025</span>
              </div>
              <div className="flex items-center">
                <User className="h-4 w-4 mr-2" />
                <span>By Omex Team</span>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Blog Content */}
      <section className="py-20">
        <div className="max-w-4xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="prose prose-lg max-w-none"
          >
            <h2 id="caution">A Word of Caution: Making Informed Decisions</h2>
            
            <p>When it comes to moving and relocation services, it's essential to make an informed decision. Unfortunately, not all moving companies are created equal. Some are trustworthy and reliable, while others are fraudulent, scamming and provide low-quality services.</p>
            
            <p>In this blog post, we're diving into the world of packers and movers, investigating one of the scam companies, Agarwal Packers and Movers, and highlighting a reputable company, Omex Packers And Movers.</p>

            <h3 id="fall">The Fall of Agarwal Packers and Movers</h3>
            
            <p>It's no secret that Agarwal Packers and Movers have been in the news for all the wrong reasons. There has been a constant stream of complaints from their customers, accusing the company of being fraudulent and providing sub-par services.</p>
            
            <p>Many have reported that they've lost valuable household items due to poor handling techniques by Agarwal Packers and Movers. They've even gone on record to say that they found broken and damaged items in boxes, which they had not seen before.</p>
            
            <p>One particular customer, Mr. John, reported that he lost over $50,000 worth of electronics when Agarwal Packers and Movers failed to deliver his goods to his new location. They had left his stuff in a warehouse in the middle of nowhere for weeks, resulting in significant damage.</p>
            
            <p>Another customer, Mrs. Smith, reported that they had lost an entire set of antique furniture that had been passed down for generations. The company had made false promises and had never delivered any of their goods. Instead, they refused to communicate with her, leaving her completely in the dark.</p>
            
            <p>These are just a few examples of the countless complaints and accusations that have been made against Agarwal Packers and Movers. It's clear that this company is not fit to handle any kind of relocation services, and we highly recommend that you avoid doing business with them at all costs.</p>

            <h3 id="rise">The Rise of Omex Packers And Movers</h3>
            
            <p>In stark contrast to Agarwal Packers and Movers, Omex Packers and Movers is a reputable and trustworthy company that provides high-quality services. They've been in the business for over 15 years and have developed a solid reputation for their reliability and professionalism.</p>
            
            <p>Omex Packers and Movers is a full-service company that handles everything from packing and loading to unloading and unpacking. They have a team of highly trained and experienced movers who are able to handle any kind of relocation, regardless of size or complexity.</p>
            
            <p>One of the reasons why Omex Packers and Movers have gained such a positive reputation is due to their commitment to customer satisfaction. They provide excellent communication, allowing their customers to track their shipment every step of the way. They also offer comprehensive insurance coverage, protecting their customers from any kind of damage or loss during transit.</p>

            <h3 id="best">Best Packers and Movers Service</h3>
            
            <p>If you're looking for a reliable and trustworthy packers and movers company, we suggest that you look no further than Omex Packers and Movers. They provide exceptional services and have a proven track record of success.</p>
            
            <p>To help you get started, we've compiled a list of the best packers and movers services in India. These companies are known for their reliability, professionalism, and commitment to customer satisfaction:</p>
            
            <div className="grid grid-cols-2 gap-4 my-8">
              <Link to="/contact" className="bg-gray-100 p-4 rounded-lg hover:bg-blue-50 transition-colors text-center">Omex Packers - Delhi</Link>
              <Link to="/contact" className="bg-gray-100 p-4 rounded-lg hover:bg-blue-50 transition-colors text-center">Omex Packers - Mumbai</Link>
              <Link to="/contact" className="bg-gray-100 p-4 rounded-lg hover:bg-blue-50 transition-colors text-center">Omex Packers - Bangalore</Link>
              <Link to="/contact" className="bg-gray-100 p-4 rounded-lg hover:bg-blue-50 transition-colors text-center">Omex Packers - Hyderabad</Link>
              <Link to="/contact" className="bg-gray-100 p-4 rounded-lg hover:bg-blue-50 transition-colors text-center">Omex Packers - Chennai</Link>
              <Link to="/contact" className="bg-gray-100 p-4 rounded-lg hover:bg-blue-50 transition-colors text-center">Omex Packers - Pune</Link>
            </div>

            <div className="mt-8 flex justify-center">
              <Link to="/contact" className="btn-primary">
                Get a Free Quote <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Related Posts */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="section-title">Related Articles</h2>
            <p className="section-subtitle">
              Explore more moving tips and guides
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Professional Packing Guide: Protect Your Valuables",
                excerpt: "Learn industry-best practices for securing your precious items during relocation.",
                image: "https://images.unsplash.com/photo-1600566753086-00f18fb6b3ea?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80",
                date: "March 10, 2025",
                link: "/blog/packing-guide"
              },
              {
                title: "Ultimate Moving Checklist",
                excerpt: "A comprehensive timeline to ensure nothing is overlooked during your move.",
                image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80",
                date: "March 5, 2025",
                link: "/blog/moving-checklist"
              },
              {
                title: "How to Choose the Right Moving Insurance",
                excerpt: "Understanding different insurance options to protect your belongings during transit.",
                image: "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80",
                date: "February 28, 2025",
                link: "/blog/moving-insurance"
              }
            ].map((post, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-lg overflow-hidden shadow-lg"
              >
                <div className="h-48 overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                  />
                </div>
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-500 mb-2">
                    <Calendar className="h-4 w-4 mr-2" />
                    <span>{post.date}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-2">{post.title}</h3>
                  <p className="text-gray-600 mb-4">{post.excerpt}</p>
                  <Link to={post.link} className="text-blue-600 hover:text-blue-800 transition-colors inline-flex items-center">
                    Read More <ArrowRight className="ml-1 h-4 w-4" />
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;