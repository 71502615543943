import React from 'react';
import { motion } from 'framer-motion';
import { Package, Truck, Home, Building, Box, ShieldCheck } from 'lucide-react';

const services = [
  {
    icon: <Home className="h-12 w-12 text-blue-600" />,
    title: "Residential Moving",
    description: "Complete home relocation services with careful handling of your belongings"
  },
  {
    icon: <Building className="h-12 w-12 text-blue-600" />,
    title: "Commercial Moving",
    description: "Office and business relocation with minimal disruption to your operations"
  },
  {
    icon: <Package className="h-12 w-12 text-blue-600" />,
    title: "Packing Services",
    description: "Professional packing using high-quality materials for maximum protection"
  },
  {
    icon: <Box className="h-12 w-12 text-blue-600" />,
    title: "Storage Solutions",
    description: "Secure storage facilities for short-term and long-term needs"
  },
  {
    icon: <Truck className="h-12 w-12 text-blue-600" />,
    title: "Vehicle Transportation",
    description: "Safe transportation of cars, bikes, and other vehicles"
  },
  {
    icon: <ShieldCheck className="h-12 w-12 text-blue-600" />,
    title: "Insurance Coverage",
    description: "Comprehensive insurance coverage for your peace of mind"
  }
];

const Services = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="section-title">Our Services</h2>
          <p className="section-subtitle">
            Comprehensive moving solutions tailored to your needs
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="service-card"
            >
              <div className="flex justify-center mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;