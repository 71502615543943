import React from 'react';
import { motion } from 'framer-motion';
import { Package, Truck, Home, Building, Box, ShieldCheck } from 'lucide-react';

const Services = () => {
  const services = [
    {
      icon: <Home className="h-12 w-12 text-blue-600" />,
      title: "Residential Moving",
      description: "Complete home relocation services with careful handling of your belongings"
    },
    {
      icon: <Building className="h-12 w-12 text-blue-600" />,
      title: "Commercial Moving",
      description: "Office and business relocation with minimal disruption to your operations"
    },
    {
      icon: <Package className="h-12 w-12 text-blue-600" />,
      title: "Packing Services",
      description: "Professional packing using high-quality materials for maximum protection"
    },
    {
      icon: <Box className="h-12 w-12 text-blue-600" />,
      title: "Storage Solutions",
      description: "Secure storage facilities for short-term and long-term needs"
    },
    {
      icon: <Truck className="h-12 w-12 text-blue-600" />,
      title: "Vehicle Transportation",
      description: "Safe transportation of cars, bikes, and other vehicles"
    },
    {
      icon: <ShieldCheck className="h-12 w-12 text-blue-600" />,
      title: "Insurance Coverage",
      description: "Comprehensive insurance coverage for your peace of mind"
    }
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-5xl font-bold mb-6">Our Services</h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Comprehensive moving solutions tailored to your needs
            </p>
          </motion.div>
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white p-8 rounded-lg shadow-lg"
              >
                <div className="flex justify-center mb-4">{service.icon}</div>
                <h3 className="text-xl font-bold text-center mb-4">{service.title}</h3>
                <p className="text-gray-600 text-center">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4">Our Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We follow a systematic approach to ensure a smooth moving experience
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {[
              { step: "1", title: "Initial Consultation", description: "We discuss your requirements and provide a free quote" },
              { step: "2", title: "Planning", description: "We create a detailed moving plan tailored to your needs" },
              { step: "3", title: "Packing & Loading", description: "Professional packing and careful loading of your items" },
              { step: "4", title: "Delivery & Setup", description: "Safe transportation and setup at your new location" }
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
                className="text-center"
              >
                <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">
                  {step.step}
                </div>
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;